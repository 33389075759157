import React from 'react'


export default function BlogPage() {
  return (
    <div>
      <h3>Latests posts</h3>
      <p>wtf</p>
    </div>
  )
}

// export const pageQuery = graphql`
// query BlogIndexQuery {

// }
// `

